import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지엑스, 리얼5G 서비스와 혁신적인 기술로 딥테크 통신사 지향… ‘기술과 서비스를 혁신하는 고객 중심의 새로운 통신사 될 것’",
  "| 주파수와 통신설비에 총 6,128억원… ‘타 통신사 5G 투자 대비 18분의 1 수준’으로 출시 3년 후 흑자전환 목표",
  "| 절감한 투자비는 R&D와 28GHz 서비스에 투자… 국내 고용 창출과 기술 혁신에 이바지 할 것",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/50/50_img1.jpg" style={{ width: "300px", margin: "auto" }} alt="CI" />
      </div>
      <div className="desc">&lt;서상원 스테이지엑스 대표&gt;</div>
    </div>
    <p>
      <br />
      <br />
      "기술과 서비스를 혁신하는 새로운 딥테크 통신사 스테이지엑스"
      <br />
      <br />
      서상원 스테이지엑스 대표(이하 서 대표)는 7일 사업 전략과 계획에 대해 ‘스테이지엑스 미디어데이 행사’에서 이같이 밝혔다.
      <br />
      <br />
      특히, △고객 중심의 파격적인 요금제 △혁신기술을 통한 리얼5G 통신경험 △믿을 수 있는 모두의 통신사 라는 스테이지엑스의 3 가지
      지향점을 제시하고 이를 구체적으로 설명하며 신규사업자로서의 포부를 드러냈다.
      <br />
      <br />
      서 대표는 “스테이지엑스가 생각하는 고객 중심의 요금제란 고객에 필요한 무언가를 더하는 것이 아니라, 필요하지 않은 것들을
      과감하게 제거하는 것” 이라며, “각종 수수료와 유통 구조를 바꿔 파격적인 가격의 요금제를 제공할 수 있다는 것”이라고 덧붙였다.
      <br />
      <br />
      스테이지엑스는 설비와 인프라 투자에도 과감한 혁신을 시도할 예정이다. 통신사 서비스 운영을 담당하는 코어망 전체를 클라우드로
      가상화할 예정으로, 이를 통해 확장성, 가용성, 경제성을 확보하면서 빠르고 효율적인 비용으로 구축 가능하다는 것이다.
      <br />
      <br />
      비용 절감을 위해 적극적인 AI 기술 도입도 함께 구상 중이다. 망 품질 관리부터 고객 응대까지 모든 운영을 담당하는 AI기술로
      운영비를 절감해 추가적인 요금 인하와 수익성 향상이 가능하다고 말했다.
      <br />
      <br />
      이번에 확보한 28GHz 주파수로는 리얼5G 서비스를 선보인다는 계획을 밝혔다. 스테이지엑스는 최초 입찰 당시인 2018년과 대비하여
      눈부시게 발전한 28GHz 관련 기술을 이용해 핫스팟을 중심으로 공연장, 병원, 학교, 공항을 비롯한 밀집지역에서 초고속, 초저지연,
      초연결의 강점을 가진 Wi-Fi를 활성화 해나갈 계획이다.
      <br />
      <br />
      또한, “북미에 이미 출시된 갤럭시 및 아이폰 28GHz 지원 단말기를 국내에서도 이용할 수 있도록 과학기술정통부와 협의해 적극
      추진하고 폭스콘과는 스테이지엑스 전용 28GHz 탑재 단말기를 개발해 브랜드 가치를 높여갈 갈 것”과 함께 “이러한 경험을 극대화할
      수 있도록 업계 최초로 통신분야 슈퍼앱을 만들어 출시할 것”이라 밝혔다.
      <br />
      <br />
      서 대표는 주파수 할당 비용에 대해서도 “28GHz 주파수와 의무 설치 기준인 6,000개 통신설비에 총 6,128억을 투자할 예정으로 이는
      타 통신사 5G 투자 금액의 약 5.5% 수준” 으로 “절감한 비용은 고객 혜택과 R&D에 투자해 국내 고용을 창출하고, 추후 28GHz 기지국
      추가 설치와 함께 기술 발전에도 선도적 역할을 할 것”이라 언급했다.
      <br />
      <br />
      스테이지엑스는 24년 2분기 내 법인 설립을 완료하고 본격적으로 서비스 구축을 시작해 25년 상반기 전국망 통신 서비스 출시를
      목표로 하고 있다. 이후 28Ghz 기지국 확대 뿐 아니라 중저대역 주파수도 추가로 확보해 자체망 구축을 진행할 계획이다.
      <br />
      <br />
    </p>
  </div>
)

const press50 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2024년 02월 07일 즉시배포 가능"
      pageInfo=""
      title="딥테크 통신사 스테이지엑스, “고객 관점에서 통신서비스 혁신” 할 것"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press50
